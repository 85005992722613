import { combineReducers } from 'redux';
import accountReducer from './data-slices/admin/accountSlice';
import usersReducer from './data-slices/admin/userSlice';
import roleReducer from './data-slices/admin/roleSlice';
import complaintCaseReducer from './data-slices/admin/complaintCaseSlice';
import productSliceReducer from './data-slices/admin/productSlice';
import productMasterSliceReducer from './data-slices/admin/productMasterSlice';
import productTypesSliceReducer from './data-slices/admin/productTypesSlice';
import translationsSliceReducer from './data-slices/admin/tranlationsSlice';
import mailTemplatesSliceReducer from './data-slices/admin/mailTemplateSlice';
import keywordsSliceReducer from './data-slices/admin/keywordsSlice';
import menusSliceReducer from './data-slices/admin/menusSlice';
import helpCenterSliceReducer from './data-slices/admin/helpCenterSlice';
import subProductStepsSliceReducer from './data-slices/admin/subProductStepsSlice';
import customFieldsSliceReducer from './data-slices/admin/customFieldsSlice';
import flowBuilderSliceReducer from './data-slices/admin/flowBuilderSlice';
import publicSliceReducer from './data-slices/admin/publicSlice';
import bankMasterSliceReducer from './data-slices/admin/bankMasterSlice';

const admin = combineReducers({
    account: accountReducer,
    bankMasters: bankMasterSliceReducer,
    menus: menusSliceReducer,
    translations: translationsSliceReducer,
    users: usersReducer,
    customFields: customFieldsSliceReducer,
    roles: roleReducer,
    subProduct: productMasterSliceReducer,
    product: productSliceReducer,
    productTypes: productTypesSliceReducer,
    subProductSteps: subProductStepsSliceReducer,
    keywords: keywordsSliceReducer,
    complaintCase: complaintCaseReducer,
    mailTemplates: mailTemplatesSliceReducer,
    posts: helpCenterSliceReducer,
    flowBuilder: flowBuilderSliceReducer,
    public: publicSliceReducer,
})

export default admin;